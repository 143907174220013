import React, { useEffect } from 'react';
import { BehindFeatureFlag, formatVertical } from '@moda/portal-stanchions';
import { useParams } from 'react-router-dom';
import { Gender } from '../../generated/types';
import { usePreferences } from '../../hooks/usePreferences';
import { PageMeta } from '../../components/PageMeta';
import { HomePage } from './HomePage';

export const HomePageContainer: React.FC = () => {
  const { vertical = 'women' } = useParams<{ vertical?: string }>();
  const { setPreference } = usePreferences();

  useEffect(() => {
    if (vertical !== Gender.women) return;
    setPreference('vertical', Gender.women);
  }, [setPreference, vertical]);

  const verticalText = formatVertical(vertical);
  const title = `${verticalText} Designer Clothing Collections & Runway Fashion`;
  const description =
    vertical === 'women'
      ? 'Shop the latest fashion, jewelry & home at Moda Operandi, from designers including Isabel Marant, Oscar de la Renta, Johanna Ortiz, Prada & more.'
      : `Shop the latest ${verticalText} designer fashion collections & clothing at Moda Operandi.`;

  return (
    <>
      <PageMeta title={title} description={description} />
      <BehindFeatureFlag flagName={`display-${vertical}`} showWhenValueIs={true}>
        <HomePage variation />
      </BehindFeatureFlag>
      <BehindFeatureFlag
        flagName={`display-${vertical}`}
        showWhenValueIs={false}
        showIfFlagIsUnavailable
      >
        <HomePage />
      </BehindFeatureFlag>
    </>
  );
};
